<template>
  <Layout>
      <fleet-header :id="$route.params.id" />
      <div v-if="!this.$apollo.queries.car.loading" class="animated fadeIn">

        <b-row>
          <b-col>
            <card-widget
              v-if="crashes"
              title="Último sinistro"
              :value="new Date(crashes[0].date).toLocaleString('pt-BR')"
              icon="fa fa-ambulance"
            />
          </b-col>

          <b-col>
            <card-widget
              v-if="maintenances"
              title="Última manutenção"
              :value="new Date(maintenances[0].date).toLocaleString('pt-BR')"
              icon="fa fa-dashboard"
            />
          </b-col>

          <b-col>
            <card-widget
              v-if="changedItems"
              title="Última troca de peça"
              :value="new Date(changedItems[0].created_at).toLocaleString('pt-BR')"
              icon="fa fa-wrench"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card>
              <card-title title="Histórico de sinistros"></card-title>
              <b-card-body class="animated fadeIn p-0">
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :data="crashes"
                  :fields="fields"
                  @vuetable:row-clicked="showItem"
                >
                </vuetable>
                <b-modal id="modal" :title="infoModal.date" ok-only>
                  <div>
                    <strong>Situação:</strong>
                    <p>{{ infoModal.situation }}</p>
                  </div>
                  <div>
                    <strong>Descrição:</strong>
                    <p>{{ infoModal.description }}</p>
                  </div>

                  <div>
                    <strong>Mais de um envolvido?</strong>
                    <p>{{ infoModal.more_than_one }}</p>
                  </div>

                </b-modal>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col>
            <b-card>
              <card-title title="Histórico de manutenções"></card-title>
              <b-card-body class="animated fadeIn p-0">
                <div class="table-responsive">
                  <vuetable
                    ref="vuetable-maintenances"
                    :api-mode="false"
                    :data="maintenances"
                    :fields="maintenanceFields"
                    @vuetable:row-clicked="showMaintenance"
                  >
                    <div slot="status" slot-scope="data">
                      <b-badge :variant="setStatusBadge(data.rowData.status)">
                        {{ data.rowData.status }}
                      </b-badge>
                    </div>
                  </vuetable>

                  <b-modal v-if="infoMaintenance.date" id="modal-maintenance" :title="infoMaintenance.date.toLocaleString('pt-BR')" ok-only>
                    <div>
                      <strong>Fonte:</strong>
                      <p>{{ infoMaintenance.source }}</p>
                    </div>

                    <div v-if="infoMaintenance.comments">
                      <strong>Comentários:</strong>
                      <p>{{ infoMaintenance.comments }}</p>
                    </div>

                    <div v-if="infoMaintenance.url">
                      <strong>URL:</strong>
                      <p>
                        <a :href="infoMaintenance.url" target="_blank">{{ infoMaintenance.url }}</a>
                      </p>
                    </div>
                  </b-modal>
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col>
            <b-card>
              <card-title title="Trocas de peças"></card-title>
              <b-card-body class="animated fadeIn p-0">
                <div class="table-responsive">
                  <vuetable
                    ref="vuetable-items"
                    :api-mode="false"
                    :data="changedItems"
                    :fields="changedItemsFields"
                    @vuetable:row-clicked="showChangedItems"
                  >
                    <div slot="cilia_id" slot-scope="data">
                      <a :href="data.rowData.cilia_id" target="_blank">
                        <i class="fa fa-link text-info" />
                      </a>
                    </div>
                    <div slot="url" slot-scope="data">
                      <a :href="data.rowData.url" target="_blank">
                        <i class="fa fa-link text-info" />
                      </a>
                    </div>
                  </vuetable>
                </div>

                <b-modal id="modal-changed-items" :title="(new Date(itemsModal.data)).toLocaleString('pt-BR')" ok-only>
                  <div>
                    <strong>Criticidade:</strong>
                    <p>{{ itemsModal.criticality }}</p>
                  </div>
                  <div>
                    <strong>Pipefy:</strong>
                    <p>
                      <a :href="itemsModal.url" target="_blank">{{ itemsModal.url }}</a>
                    </p>
                  </div>

                  <div>
                    <strong>Cilia:</strong>
                    <p>
                      <a :href="itemsModal.cilia_id" target="_blank">{{ itemsModal.cilia_id }}</a>
                    </p>
                  </div>

                  <div v-if="itemsModal.items">
                    <strong>Itens:</strong>
                    <ul>
                      <li v-for="item, index in itemsModal.items.split(',')" :key="`item-${index}`">
                        <p>{{ item }}</p>
                      </li>
                    </ul>
                  </div>
                </b-modal>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div v-else class="animated fadeIn">
        <content-loading/>
      </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import ContentLoading from '@components/content-loading';
import CardTitle from "@components/shared/card-title";
import FleetHeader from '@components/fleet/header';
import CAR_PROFILE from '@graphql/car/queries/profile.gql';
import Vuetable from 'vuetable-2';
import CardWidget from '@components/shared/card-widget';
import { Auth } from 'aws-amplify';
import { formatStatus } from '@utils/car';

export default {
  name: 'FleetHistory',
  page: {
    title: 'Historico',
  },
  components: {
    Layout,
    ContentLoading,
    FleetHeader,
    Vuetable,
    CardTitle,
    CardWidget,
  },
  data() {
    return {
      infoModal: {},
      itemsModal: {},
      infoMaintenance: {},
      car: {},
      crashes: null,
      maintenances: null,
      changedItems: null,
      hasError: false,
      errorsInfo: {
        message: '',
        type: ''
      },
      data: [
        {name: 'test', date: '2021-09-11 12:45:23'},
        {name: 'aaa', date: '2021-10-09 12:45:23'},
      ],
      fields: [
        {
          name: 'date',
          title: 'Data',
          formatter: value => new Date(value).toLocaleString('pt-BR')
        },
        {
          name: 'more_than_one',
          title: 'Mais de um envolvido?'
        }
      ],
      maintenanceFields: [
        {
          name: 'date',
          title: 'Data',
          formatter: value => new Date(value).toLocaleString('pt-BR')
        },
        {
          name: 'status',
          title: 'Tipo',
        },
        {
          name: 'source',
          title: 'Fonte'
        }
      ],
      changedItemsFields: [
        {
          name: 'created_at',
          title: 'Data',
          formatter: value => new Date(value).toLocaleString('pt-BR')
        },
        {
          name: 'criticality',
          title: 'Criticidade'
        },
        {
          name: 'cilia_id',
          title: 'Cilia',
        },
        {
          name: 'url',
          title: 'Pipefy',
        }
      ]
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    const session = await Auth.currentSession();
    const authToken = session.getIdToken().getJwtToken();
    const response = await fetch(`https://api.kovi.us/cars-service/maintenance-history/${id}`, {
      headers: {
        'authorization': authToken ? authToken : ''
      }
    })
    const data = await response.json()
    this.crashes = data.crashes
    const maintenances = [
      ...data.cilia_maintenance.map((item) => ({
        date: new Date(item.created_at),
        status: item.status || item.service,
        comments: `Ordem de serviço: ${item.os}`,
        duration_days: item.duration_days,
        source: 'Rental'
      })),
      ...data.pipefy_maintenance.map((item) => ({
        date: new Date(item.created_at),
        status: item.status || item.service || 'Manutenção',
        comments: item.comments || `Pipefy card: ${item.card}`,
        url: item.url,
        source: 'Pipefy'
      }))
    ]
    this.maintenances = maintenances.sort((a,b) => b.date - a.date)
    this.changedItems = data.car_changed_items
  },
  computed: {
    hasLicenseNumber() {
      return this.car?.license_number;
    },
  },
  apollo: {
    car: {
      query: CAR_PROFILE,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result(data) {
        this.getError();
      },
      error(err) {
        const error = { ...err?.graphQLErrors, stack: err.stack };
        if(window.newrelic) {
          window.newrelic.noticeError(new Error(`CAR_HISTORY_ERROR: ${err.message} code: ${err?.graphQLErrors[0]?.code}`), error);
        }
      }
    },
  },
  methods: {
    getError() {
      if (!this.hasVin && !this.getCarProfileStatusCode500()) {
        this.errorsInfo = { type: 'info', message: this.$t('fleetProfile.labels.waitingDenatranSync') };
        this.hasError = true;
      } else if (this.getCarProfileStatusCode500()) {
        this.errorsInfo = { type: 'danger', message: this.$t('fleetProfile.labels.vehicleDataError') };
        this.hasError = true;
      } else {
        this.errorsInfo = { message: '', type: '' };
        this.hasError = false;
      }
    },
    getCarProfileStatusCode500() {
      return this.carProfileStatusCode === 500;
    },
    showItem({ data }) {
      this.infoModal = data
      this.$bvModal.show('modal')
    },
    showChangedItems({ data }) {
      this.itemsModal = data
      this.$bvModal.show('modal-changed-items')
    },
    showMaintenance({ data }) {
      this.infoMaintenance = data
      this.$bvModal.show('modal-maintenance')
    },
    setStatusBadge(status) {
      return formatStatus(status);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
