<script>
  export default {
    name: "CardTitle",
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<template>
  <div class="header d-flex justify-content-between align-items-center">
    <div class="card-title">
      <h4 class="font-weight-bold text-uppercase section-title">
        {{ title }}
      </h4>
    </div>
    <slot />
  </div>
</template>

<style scoped>
  .section-title {
    font-size: 12pt;
  }
</style>
