var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header d-flex justify-content-between align-items-center" },
    [
      _c("div", { staticClass: "card-title" }, [
        _c(
          "h4",
          { staticClass: "font-weight-bold text-uppercase section-title" },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }