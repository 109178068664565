var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("fleet-header", { attrs: { id: _vm.$route.params.id } }),
      !this.$apollo.queries.car.loading
        ? _c(
            "div",
            { staticClass: "animated fadeIn" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _vm.crashes
                        ? _c("card-widget", {
                            attrs: {
                              title: "Último sinistro",
                              value: new Date(
                                _vm.crashes[0].date
                              ).toLocaleString("pt-BR"),
                              icon: "fa fa-ambulance",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _vm.maintenances
                        ? _c("card-widget", {
                            attrs: {
                              title: "Última manutenção",
                              value: new Date(
                                _vm.maintenances[0].date
                              ).toLocaleString("pt-BR"),
                              icon: "fa fa-dashboard",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _vm.changedItems
                        ? _c("card-widget", {
                            attrs: {
                              title: "Última troca de peça",
                              value: new Date(
                                _vm.changedItems[0].created_at
                              ).toLocaleString("pt-BR"),
                              icon: "fa fa-wrench",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        [
                          _c("card-title", {
                            attrs: { title: "Histórico de sinistros" },
                          }),
                          _c(
                            "b-card-body",
                            { staticClass: "animated fadeIn p-0" },
                            [
                              _c("vuetable", {
                                ref: "vuetable",
                                attrs: {
                                  "api-mode": false,
                                  data: _vm.crashes,
                                  fields: _vm.fields,
                                },
                                on: { "vuetable:row-clicked": _vm.showItem },
                              }),
                              _c(
                                "b-modal",
                                {
                                  attrs: {
                                    id: "modal",
                                    title: _vm.infoModal.date,
                                    "ok-only": "",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("strong", [_vm._v("Situação:")]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.infoModal.situation)),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c("strong", [_vm._v("Descrição:")]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.infoModal.description)),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c("strong", [
                                      _vm._v("Mais de um envolvido?"),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.infoModal.more_than_one)
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        [
                          _c("card-title", {
                            attrs: { title: "Histórico de manutenções" },
                          }),
                          _c(
                            "b-card-body",
                            { staticClass: "animated fadeIn p-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "table-responsive" },
                                [
                                  _c("vuetable", {
                                    ref: "vuetable-maintenances",
                                    attrs: {
                                      "api-mode": false,
                                      data: _vm.maintenances,
                                      fields: _vm.maintenanceFields,
                                    },
                                    on: {
                                      "vuetable:row-clicked":
                                        _vm.showMaintenance,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "status",
                                          fn: function (data) {
                                            return _c(
                                              "div",
                                              {},
                                              [
                                                _c(
                                                  "b-badge",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        _vm.setStatusBadge(
                                                          data.rowData.status
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.rowData.status
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3537555857
                                    ),
                                  }),
                                  _vm.infoMaintenance.date
                                    ? _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            id: "modal-maintenance",
                                            title:
                                              _vm.infoMaintenance.date.toLocaleString(
                                                "pt-BR"
                                              ),
                                            "ok-only": "",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _c("strong", [_vm._v("Fonte:")]),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.infoMaintenance.source
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _vm.infoMaintenance.comments
                                            ? _c("div", [
                                                _c("strong", [
                                                  _vm._v("Comentários:"),
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.infoMaintenance
                                                        .comments
                                                    )
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                          _vm.infoMaintenance.url
                                            ? _c("div", [
                                                _c("strong", [_vm._v("URL:")]),
                                                _c("p", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: _vm
                                                          .infoMaintenance.url,
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.infoMaintenance
                                                            .url
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        [
                          _c("card-title", {
                            attrs: { title: "Trocas de peças" },
                          }),
                          _c(
                            "b-card-body",
                            { staticClass: "animated fadeIn p-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "table-responsive" },
                                [
                                  _c("vuetable", {
                                    ref: "vuetable-items",
                                    attrs: {
                                      "api-mode": false,
                                      data: _vm.changedItems,
                                      fields: _vm.changedItemsFields,
                                    },
                                    on: {
                                      "vuetable:row-clicked":
                                        _vm.showChangedItems,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cilia_id",
                                          fn: function (data) {
                                            return _c("div", {}, [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: data.rowData.cilia_id,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-link text-info",
                                                  }),
                                                ]
                                              ),
                                            ])
                                          },
                                        },
                                        {
                                          key: "url",
                                          fn: function (data) {
                                            return _c("div", {}, [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: data.rowData.url,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-link text-info",
                                                  }),
                                                ]
                                              ),
                                            ])
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      332457545
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-modal",
                                {
                                  attrs: {
                                    id: "modal-changed-items",
                                    title: new Date(
                                      _vm.itemsModal.data
                                    ).toLocaleString("pt-BR"),
                                    "ok-only": "",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("strong", [_vm._v("Criticidade:")]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.itemsModal.criticality)
                                      ),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c("strong", [_vm._v("Pipefy:")]),
                                    _c("p", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.itemsModal.url,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.itemsModal.url))]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c("strong", [_vm._v("Cilia:")]),
                                    _c("p", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.itemsModal.cilia_id,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.itemsModal.cilia_id)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm.itemsModal.items
                                    ? _c("div", [
                                        _c("strong", [_vm._v("Itens:")]),
                                        _c(
                                          "ul",
                                          _vm._l(
                                            _vm.itemsModal.items.split(","),
                                            function (item, index) {
                                              return _c(
                                                "li",
                                                { key: `item-${index}` },
                                                [
                                                  _c("p", [
                                                    _vm._v(_vm._s(item)),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }